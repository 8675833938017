<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on" :disabled="disabled" :to="to">
        <v-icon :color="iconColor">{{ iconName }}</v-icon>
      </v-btn>
    </template>
    {{ toolTip }}
  </v-tooltip>
</template>

<script>
// design
import { iconMenuRight } from "@/design/icon/iconConst";

// mixins
import { principalMixin } from "@/mixins/shared/user/principalMixin";

// model
import {
  findRecordState,
  findRecordType,
  recordState,
  recordType
} from "@/model/record/recordModel";

// services
import { isRecordExpandable } from "@/services/record/recordService";
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";

export default {
  name: "ExpandRecordButton",
  mixins: [principalMixin],
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,

    /**
     * Navigate to
     */
    to: undefined,

    /**
     * disabled expand button
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconName: iconMenuRight
    };
  },
  computed: {
    /**
     * record id
     * @return {*|number} record id
     */
    id() {
      return this.record?.id ?? -1;
    },

    /**
     * record Name
     * @return {String|string} record Name
     */
    recordName() {
      return this.record?.name ?? "Record";
    },

    /**
     * record state id
     * @return {Number|number} record state id
     */
    stateId() {
      return this.record?.stateId ?? -1;
    },

    /**
     * record Type id
     * @return {Number|number} record Type id
     */
    recordTypeId() {
      return this.record?.recordTypeId ?? recordType.RECORD;
    },

    /**
     * record Type
     * @return {{name: string, icon: string, id: number}|number} record Type
     */
    recordType() {
      return findRecordType(this.recordTypeId) ?? recordType.RECORD;
    },

    /**
     * record child Count
     * @return {*|number} record child count
     */
    childCount() {
      return this.record?.children ?? 0;
    },

    /**
     * icon Color
     * @return {string|String|undefined} icon Color
     */
    iconColor() {
      return this.childCount > 0 || this.childCount < 0
        ? undefined
        : new Color(
            colorMD.grey,
            variantType.lighten,
            variantNumber.n1
          ).getClassColor();
    },

    /**
     * tool Tip
     * @return {String|string}
     */
    toolTip() {
      try {
        let attributeComment = undefined;
        if (this.record?.isLocked ?? false) {
          attributeComment = findRecordState(recordState.locked)?.name;
        } else if (this.record?.isReadOnly ?? false) {
          attributeComment = findRecordState(recordState.readOnly)?.name;
        }

        let stateComment = undefined;
        if (this.stateId === recordState.checkedOut) {
          const state = findRecordState(this.stateId)?.name ?? "?";
          stateComment = this.isUserStateOwner(this.record)
            ? `${state} by me`
            : `${state} by ${this.record?.owner}`;
        }

        let name = this.recordType?.name ?? this.recordName;
        if (stateComment) {
          name = `${name} ${stateComment}`;
          if (attributeComment) {
            name = `${name} & ${attributeComment}`;
          }
        } else {
          if (attributeComment) {
            name = `${name} ${attributeComment}`;
          }
        }

        const childCount = this.childCount;
        const childCountInfo = childCount > 1 ? "children" : "child";

        return isRecordExpandable(this.record)
          ? childCount >= 0
            ? `Expand ${name} with ${childCount} ${childCountInfo}`
            : `Expand ${name}`
          : `Select ${name}`;
      } catch (e) {
        console.error(e);
        return `Couldn't provide a tool tip, since ${e.toString()}`;
      }
    }
  }
};
</script>
